import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    cookieConsent: null,
    permissions: {
        functionality_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied', 
        ad_personalization: 'denied',
        analytics_storage: 'denied'
    }
};

export default createReducer(initialState, {

    [actions.addCookieConsent]: (state, action) => {
        const { payload } = action;
        const { consent, permissions={} } = payload;

        return {
            ...state,
            cookieConsent: consent,
            permissions: permissions
        }
    }

});
