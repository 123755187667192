
import { useStore } from 'react-redux';

import { Helmet } from 'react-helmet-async';


const GoogleTagManager = ({ trackingId=null }) => {
    const store = useStore();
    const { cookieconsent } = store.getState();

    if (!trackingId) { 
        return null; 
    }

    return (
        <>
            <Helmet>
                <script id="google-tagmanager">
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    window.gtag = gtag;

                    gtag('consent', 'default', ${ JSON.stringify(cookieconsent.permissions) });
        
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${trackingId}');
                    `}
                </script>
            </Helmet>
            <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${trackingId}`} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
        </>
    )

}

export default GoogleTagManager;