import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import CookieConsent from '../components/cookieconsent/CookieConsent';
import GoogleTagManager from '../modules/google/GoogleTagManager';

const Layout = () => {
    return (
        <>
            <Helmet
                defaultTitle={ 'Hanos Kerstpakketten' }
                titleTemplate={ '%s | Hanos Kerstpakketten' }
            />

            <Header />

            <main>
                <Outlet />
            </main>
            
            <CookieConsent />
            <GoogleTagManager trackingId="GTM-MTG3WJV"/>
            <Footer />
        </>
    );
}

export default Layout;