import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    cart: {},
    cart_updated: null,
    delivery_deliver: true, 
    delivery_pickup: true, 
    minimum_order_quantity: 10,
    usps: []
};

export default createReducer(initialState, {
    [actions.addCart]: (state, action) => {
        const { payload } = action;

        if (Object.keys(payload).length === 0) {
            return {
                ...state,
                cart: payload,
                cart_updated: null
            }
        } else {
            return {
                ...state,
                cart: payload,
                cart_updated: new Date().getTime()
            }
        }

    },
    [actions.addConfig]: (state, action) => {
        const { payload } = action;

        const { delivery_deliver, delivery_pickup, minimum_order_quantity, usps } = payload;
        
        state.delivery_deliver = delivery_deliver;
        state.delivery_pickup = delivery_pickup;
        state.minimum_order_quantity = minimum_order_quantity;
        state.usps = usps;
       
    },

});
