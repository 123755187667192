const gtag = {

    pageview: (path, params={}) => {
        if (typeof window === undefined || window?.gtag === undefined) { return; }
        
        try {
            window.gtag('event', 'page_view', {
                page_location: path,
                ...params
            });
         
        } catch (error) {
            
        }
   
    },

    event: (event_name, data) => {
        if (typeof window === undefined || window?.gtag === undefined) { return; }

        try {
            window.gtag('event', event_name, data); 
        } catch (error) {
            
        }

    },
    
    set: (property, data) => {
        if (typeof window === undefined || window?.gtag === undefined) { return; }
        try {
            window.gtag('set', property, data);
        } catch (error) {
            
        }
        
    },

    consent: (consent_arg, consent_params) => {
        if (typeof window === undefined || window?.gtag === undefined) { return; }

        try {
            window.gtag('consent', consent_arg, consent_params);
        } catch (error) {
            
        }

    
    }

}

export default gtag;