import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from '../grid';

import { Form, Checkbox } from '../form';

import { gtag } from '../../modules/google';
import { addCookieConsent } from '../../redux/cookieconsent/actions';

import Portal from '../portal/Portal';

import Button from '../form/button/Button';

import classes from './CookieConsent.module.css'

const CookieConsent = () => {
    const dispatch = useDispatch();

    const { cookieConsent } = useSelector((state) => state.cookieconsent);

    if (cookieConsent) { return null; }

    const onSubmit = async (valid, resetForm) => {

        const permissions =  {
            functionality_storage: 'granted',
            ad_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted', 
            analytics_storage: 'granted'
        }

        gtag.consent('update', permissions);

        dispatch(addCookieConsent({ 
            consent: true, 
            permissions: permissions
        }));
    
    }

    const refuse = () => {

        const permissions =  {
            functionality_storage: 'granted',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied', 
            analytics_storage: 'denied',
        }

        gtag.consent('update', permissions);

        dispatch(addCookieConsent({ 
            consent: true, 
            permissions: permissions
        }));
    }

    return (
        <>
            <Portal>
    
                <div className={classes.root}>
                    <Container xl margins g-xs={2} className={classes.cookie_container}>
                        <Form onSubmit={onSubmit} id="consent-form" className={classes.form}>
                            <Row>
                                <Col md={12}>
                                    <div className={classes.text}>
                                        <h3>
                                            Deze website maakt gebruik van cookies
                                        </h3>
                                        <p>
                                            HANOS kerstpakketten maakt gebruik van cookies en vergelijkbare technieken. Hanoskerstpakketten.nl gebruikt functionele, analytische en marketing cookies en verzamelt daarmee informatie over het gebruik van de website om u een optimale gebruikerservaring te bieden. <Link to="/cookies">Klik hier</Link> voor informatie over ons cookiebeleid. Wanneer u op ‘Accepteer cookies' klikt, geeft u toestemming voor het plaatsen van cookies. Wilt u geen cookies toestaan? Kies dan voor 'weigeren’.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className={classes.buttons_wrapper}>
                                        <Button type="submit">
                                            <span>Accepteren</span>
                                        </Button>
                                        
                                        <Button type="button" variant="transparent" onClick={refuse}>
                                            <span>Weigeren</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
        
            </Portal>
        </>
    );
}

export default CookieConsent;