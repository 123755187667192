import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {v4 as uuidv4} from 'uuid';

import ReCAPTCHA from 'react-google-recaptcha';

import { Col, Row, Container } from '../../components/grid';
import {
    Input,
    Button,
    Form,
    Select,
    Textarea,
} from '../../components/form';
import { ClipboardCheck } from '../../components/icons';

import useRequestState from '../../hooks/useRequestState';
import useLocations from '../../hooks/useLocations';
import useConfig from '../../hooks/useConfig';

import CheckoutCart from '../../components/checkout/checkout_cart/CheckoutCart';
import Support from '../../components/checkout/support/Support';
import Kerstpakketten from '../../lib/api/Kerstpakketten';
import Config from '../../lib/config/Config';

import gtag from '../../modules/google/gtag';

import classes from './Checkout.module.css';

const initialInput = { location: '', shipping: '', name: '', company: '', kvknumber: '', loyaltynumber: '', email: '', zipcode: '', housenumber: '', addition: '', streetname: '', phone: '', country: 'nederland', message: '' };

const Checkout = () => {
    const { cart } = useSelector(state => state.app);
    const { minimum_order_quantity=10, delivery_deliver, delivery_pickup } = useConfig();

    const [inputs, setInput] = useState(initialInput);
    const { success, error, sending, setRequest, setSuccess, setError } = useRequestState();
    const recaptchaRef = useRef(null);

    const navigate = useNavigate();

    const { locations, loading } = useLocations();

    const hasBranche = Object.values(cart).reduce((hasBranche, { product }) => {
        if (product?.branchproduct === true) {
            hasBranche = true;
        }
        return hasBranche;
    }, false);

    let subTotal = 0;
    let itemCount = 0;
    Object.values(cart).forEach(({ total_price, qty }) => {
        itemCount += Number(qty)
        subTotal += Number(total_price.replace(',', '.'))
    });

    const onChange = ({ target: { name, value } }) => {

        setInput({
            ...inputs,
            [name]: value
        });

    }

    const onSubmit = async (valid, resetForm) => {

        if (valid) {

            const token = await recaptchaRef.current.executeAsync();
            
            setRequest();
            try {

                await Kerstpakketten.sendQuotation({
                    ...inputs,
                    cart: cart,
                    recaptcha: token || ''
                });

                setSuccess();
                setInput(initialInput);
                resetForm();

                try {
                    gtag.event('purchase', {
                        currency: 'EUR',
                        value: subTotal,
                        transaction_id: uuidv4(),
                        items: Object.values(cart).map(({ product, qty, total_price }) => {
                            return {
                                item_id: product.sku,
                                item_name: product.name,
                                quantity: qty,
                                price: total_price,
                                affiliation: (inputs?.location ? inputs.location : '')
                            }
                        })
                    });
                } catch (error) {
                    console.log(error);
                }

                navigate('/success');
            } catch (error) {
                setError(error);
                recaptchaRef.current.reset();
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Order plaatsen</title>
                <meta name="description" content="Met behulp van onderstaand formulier kunt u uw order plaatsen. Onze HANOS Kerstspecialisten nemen uw order in behandeling en nemen contact met u op indien er vragen zijn." />
            </Helmet>

            <Container xl margins g-xs={2} className={classes.checkout_container}>
                <Row>
                    <Col md={8}>
                        <div className={classes.info}>
                            <h1>Order plaatsen</h1>
                            <p>Met behulp van onderstaand formulier kunt u uw order plaatsen. Onze HANOS Kerstspecialisten nemen uw order in behandeling en nemen contact met u op indien er vragen zijn.</p>
                            {
                                delivery_deliver
                                &&
                                <p><strong>Wanneer u kiest voor bezorging zullen wij contact met u opnemen voor de gewenste leverdag.</strong></p>
                            }
                        </div>
                        <h2 className={classes.title}>Order plaatsen</h2>
                        {
                            (itemCount < minimum_order_quantity)
                            ?
                            <Row>
                                <Col md={10}>
                                    <span className={classes.minimum_cart_amount}>Voor het bestellen van kerstpakketten geldt een minimum aantal van { minimum_order_quantity } kerstpakketten.</span>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md={10}>
                                    <Form onSubmit={onSubmit} id="checkout-form">
                                        <Row>
                                            {
                                                error && <Col ms={12} ><div className={classes.error}>Formulier kon niet worden verstuurd. Probeer het later nogmaals.</div></Col>
                                            }
                                            {
                                                success && <Col ms={12} ><div className={classes.success}>Formulier succesvol verstuurd!</div></Col>
                                            }
                                            <Col xs={12}><span className={classes.required}>* Verplicht</span></Col>
                                            {
                                                hasBranche
                                                &&
                                                <Col md={12}>
                                                    <Select name="location" label="Vestiging" loading={loading} onChange={onChange} value={inputs.location} required>
                                                        <option value=''>Selecteer vestiging</option>
                                                        {
                                                            locations.map(({ key, name }) => {
                                                                return <option key={key} value={key}>{name}</option>
                                                            })
                                                        }
                                                    </Select>
                                                </Col>
                                            }
                                            <Col md={12}>
                                                <Select name="shipping" label="Levering" onChange={onChange} value={inputs.shipping} required>
                                                    <option value=''>Selecteer verzendmethode</option>

                                                    {
                                                        delivery_pickup
                                                        &&
                                                        <option value='afhalen'>Afhalen</option>
                                                    }
                    
                                                    {
                                                        delivery_deliver
                                                        &&
                                                        <option value='bezorgen'>Bezorgen (excl. transportkosten)</option>
                                                    }
                                                    
                                                </Select>
                                            </Col>
                                            <Col md={12}>
                                                <Input name="name" label="Naam" placeholder="Naam" onChange={onChange} value={inputs.name} required />
                                            </Col>
                                            <Col md={12}>
                                                <Input name="company" label="Bedrijf" placeholder="Bedrijf" onChange={onChange} value={inputs.company} required />
                                            </Col>
                                            <Col md={12}>
                                                <Input name="kvknumber" label="KVK-nummer" placeholder="KVK-nummer" onChange={onChange} value={inputs.kvknumber} required />
                                            </Col>
                                            <Col md={12}>
                                                <Input name="loyaltynumber" label="Klantnummer" placeholder="Klantnummer" onChange={onChange} value={inputs.loyaltynumber} />
                                            </Col>
                                            <Col md={12}>
                                                <Input name="email" label="E-mailadres" placeholder="voorbeeld@email.com" onChange={onChange} value={inputs.email} required />
                                            </Col>
                                            <Col xs={5}>
                                                <Input name="zipcode" label="Postcode" placeholder="vb. 0000AB" onChange={onChange} value={inputs.zipcode} />
                                            </Col>
                                            <Col xs={3}>
                                                <Input name="housenumber" placeholder="Huisnr." onChange={onChange} value={inputs.housenumber} />
                                            </Col>
                                            <Col xs={4}>
                                                <Input name="addition" placeholder="Toevoeging" onChange={onChange} value={inputs.addition} />
                                            </Col>
                                            <Col md={12}>
                                                <Input name="streetname" label="Straat" placeholder="Straatnaam" onChange={onChange} value={inputs.streetname} />
                                            </Col>
                                            <Col md={12}>
                                                <Input name="city" label="Plaats" placeholder="Plaatsnaam" onChange={onChange} value={inputs.city} />
                                            </Col>
                                            <Col>
                                                <Select name="country" label="Land" onChange={onChange} value={inputs.country} >
                                                    <option value="nederland">Nederland</option>
                                                    <option value="duitsland">Duitsland</option>
                                                    <option value="belgië">België</option>
                                                </Select>
                                            </Col>
                                            <Col md={12}>
                                                <Input name="phone" label="Telefoonnummer" placeholder="Telefoonnummer" onChange={onChange} value={inputs.phone} required />
                                            </Col>
                                            <Col md={12}>
                                                <Textarea name="message" label="Opmerkingen" placeholder="Type hier uw speciale wensen en/of opmerkingen" onChange={onChange} value={inputs.message} />
                                            </Col>
                                            <Col md={12}>
                                                <Button variant="secondary" type="submit" style={{ float: 'right' }} loading={sending} id="checkout-submit">
                                                    {
                                                        <>
                                                            <ClipboardCheck size="24" />
                                                            <span>Order bevestigen</span>
                                                        </>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={Config.get('captcha').site_key}
                                        />
                                    </Form>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col md={4}>
                        <CheckoutCart cart={cart} />
                        <div className={classes.support}>
                            <Support />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Checkout;